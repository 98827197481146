import {Airport} from '../Forms/AirportAutocomplete'
import {Airline} from '../Forms/AirlineAutocomplete'
import {Upload} from '../Forms/Upload'
import {StatedCause} from '../ClaimForm/FlightDetailsForm'

const EMAIL_REGEX = /[^@\s]+@[^@\s]+(?:\.[^@\s]+)+/
const PHONE_NUMBER_REGEX = /^[0-9 +\-()]+$/
const TIME_REGEX = /(\d\d?):(\d\d)/


interface Validation {
    validate: () => boolean
    isValid: () => boolean
}

interface Serialization {
    serialize: () => object
    unserialize: (object) => void
}

interface FormData extends Validation, Serialization {
    getFields: () =>  FormField<any>[]
}


export class BaseFormData implements FormData {
    onChange: () => void

    getFields () : FormField<any>[] {
        return []
    }

    constructor (onChange?) {
        this.onChange = onChange
    }

    validate () {
        for (let f of this.getFields()) {
            f.validate()
        }

        return this.isValid()
    }

    onFieldChange<T>(field: FormField<T>, newValue:T) {
        if (this.onChange) {
            this.onChange()
        }
    }

    isValid () {
        for (let f of this.getFields()) {
            if (!f.isValid()) {
                return false
            }
        }

        return true
    }

    serialize () {
        let result = {}
        for (let f of this.getFields()) {
            Object.assign(result, f.serialize())
        }

        return result
    }

    unserialize (obj) {
        for (let f of this.getFields()) {
            f.unserialize(obj)
        }

        return this
    }

}



export class FormField<ValueType> implements Validation, Serialization {
    form: BaseFormData
    value: ValueType
    error: string
    field: string

    constructor (form: BaseFormData, value: ValueType, field: string) {
        this.form = form
        this.value = value
        this.field = field
    }

    setValue (v: ValueType) {
        this.value = v
        this.form.onFieldChange(this, v)
        return this
    }

    validate () {
        this.form.onChange()
        return true
    }

    isValid () {
        return !this.error
    }

    setError (err) {
        this.error = err
        this.form.onChange()
    }

    serialize () : object {
        if (this.field === null) {
            return {}
        }

        return {[this.field]: this.value}
    }

    unserialize (obj) {
        if (this.field === null) return

        this.setValue(obj[this.field])
    }
}


export class TextField extends FormField<string> {
    emptyErrorMessage: string

    constructor (form: BaseFormData, value: string, field: string, emptyErrorMessage) {
        super(form, value, field)

        this.emptyErrorMessage = emptyErrorMessage
    }

    validate () {
        if (!this.value || this.value.trim().length == 0) {
            this.setError(this.emptyErrorMessage)
        } else {
            this.setError(null)
        }

        return this.isValid()
    }
}

export class OptionalTextField extends TextField {
    constructor (form, value, field, emptyErrorMessage) {
        super(form, value, field, emptyErrorMessage)
    }

    validate () {
        this.setError(null)

        return true
    }
}


export class EmailField extends TextField {
    constructor (form, value, field) {
        super(form, value, field, "Please enter your email address")
    }

    validate () {
        super.validate()

        if (!this.error && !EMAIL_REGEX.test(this.value)) {
            this.setError("Please enter a valid email address")
        }

        return this.isValid()
    }
}


class PhoneNumberField extends FormField<string> {
    validate () {
        if (!this.value) {
            this.setError(null)
        } else {
            let value = this.value.trim()
            if (!PHONE_NUMBER_REGEX.test(this.value)) {
                this.setError("Please enter a valid phone number")
            } else {
                this.setError(null)
            }
        }

        return this.isValid()
    }
}


export class PassengerNameField extends TextField {
    constructor (form, value, field) {
        super(form, value, field, "Please enter your full name")
    }

    validate () {
        super.validate()

        if (!this.error && this.value.split(' ').length < 2) {
            this.setError("Please enter your full name (including family name)")
        }

        return this.isValid()
    }
}


class AirportField extends FormField<Airport> {
    completeValueField: string
    emptyErrorMessage: string

    constructor (form, value, field, completeValueField, emptyErrorMessage) {
        super(form, value, field)

        this.completeValueField = completeValueField
        this.emptyErrorMessage = emptyErrorMessage
    }

    validate () {
        if (!this.value) {
            this.setError(this.emptyErrorMessage)
        } else {
            this.setError(null)
        }

        return this.isValid()
    }

    serialize () {
        return {
            [this.field]: this.value.id,
        }
    }
}


class AirlineField extends FormField<Airline> {
    emptyErrorMessage: string

    constructor (form, value, field, emptyErrorMessage) {
        super(form, value, field)

        this.emptyErrorMessage = emptyErrorMessage
    }

    validate () {
        if (!this.value) {
            this.setError(this.emptyErrorMessage)
        } else {
            this.setError(null)
        }

        return this.isValid()
    }

    serialize () {
        return {
            [this.field]: this.value.id,
        }
    }
}


class DateField extends FormField<Date> {
    validate () {
        if (!this.value) {
            this.setError("Please select the planned departure date")
        } else {
            this.setError(null)
        }

        return this.isValid()
    }
}


class TimeField extends FormField<string> {
    validate () {
        let departureTime = (this.value || '').trim()
        let hours = null
        let minutes = null
        let timeMatch = TIME_REGEX.exec(departureTime)
        if (timeMatch) {
            [, hours, minutes] = timeMatch
            hours = parseInt(hours)
            minutes = parseInt(minutes)
        }

        if (timeMatch == null || hours > 23 || minutes > 59) {
            this.setError("Please enter the planned departure time (HH:MM)")
        } else {
            this.setError(null)
        }

        return this.isValid()
    }
}


class UploadField extends FormField<Upload> {
    validate () {
        if (!this.value) {
            this.setError("Please add the required file")
        } else {
            this.setError(null)
        }

        return this.isValid()
    }
}


class NestedFormField<ValueType extends BaseFormData> extends FormField<ValueType> {
    validate () {
        this.value.validate()
        return this.value.isValid()
    }
}


class NestedFormArrayField<ValueType extends BaseFormData> extends FormField<ValueType[]> {
    constructor (form, value, field) {
        if (!value) {
            value = []
        }

        super(form, value, field)
    }

    appendItem (i) {
        i.onChange = this.form.onChange
        this.value.push(i)
        this.form.onChange()
    }

    removeItem (i) {
        let idx = this.value.indexOf(i)
        if (idx !== -1) {
            this.value.splice(idx, 1)
            this.form.onChange()
        }
    }

    validate () {
        for (let i of this.value) {
            i.validate()
        }

        return this.isValid()
    }

    isValid () {
        for (let i of this.value) {
            if (!i.isValid()) {
                return false
            }
        }

        return true
    }

    serialize () {
        return {
            [this.field]: this.value.map((i) => i.serialize())
        }
    }
}


class TermsAgreementField extends FormField<boolean> {
    validate () {
        if (!this.value) {
            this.setError("You must agree to continue")
        } else {
            this.setError(null)
        }

        return this.isValid()
    }
}


export class PassengerValues extends BaseFormData {
    name: PassengerNameField = new PassengerNameField(this, '', 'name')
    boardingPassDoc: UploadField = new UploadField(this, null, null)
    idDoc: UploadField = new UploadField(this, null, null)
    requireIdDoc: boolean

    constructor (onChange, requireIdDoc) {
        super(onChange)

        this.requireIdDoc = requireIdDoc
    }

    getFields () {
        let fields = [this.name, this.boardingPassDoc]

        if (this.requireIdDoc) {
            fields.push(this.idDoc)
        }

        return fields
    }
}


export class ClaimValues extends BaseFormData {
    bookingNr: FormField<string> = new TextField(
        this,
        '',
        'booking_number',
        "Please enter your booking number"
    )
    comment: FormField<string> = new FormField<string>(this, '', 'comment')
    email: EmailField = new EmailField(this, '', 'email')
    phoneNumber: FormField<string> = new PhoneNumberField(this, '', 'phone_number')
    submitter: NestedFormField<PassengerValues>
    isAgreedToServiceFee: TermsAgreementField = new TermsAgreementField(this, false, null)
    extraPassengers: NestedFormArrayField<PassengerValues> =
        new NestedFormArrayField<PassengerValues>(this, [], 'passengers')

    requireIdDoc: boolean
    requireExplicitTermsAck: boolean

    constructor (onChange?, requireIdDoc?: boolean, requireExplicitTermsAck?: boolean) {
        super(onChange)

        this.requireIdDoc = requireIdDoc
        this.requireExplicitTermsAck = requireExplicitTermsAck

        this.submitter = new NestedFormField(
            this,
            new PassengerValues(this.onChange, this.requireIdDoc),
            null
        )
    }

    getFields () {
        let fields: FormField<any>[] = [
            this.bookingNr,
            this.comment,
            this.email,
            this.phoneNumber,
            this.submitter,
            this.extraPassengers,
        ]

        if (this.requireExplicitTermsAck) {
            fields.push(this.isAgreedToServiceFee)
        }

        return fields
    }
}


export class FlightValues extends BaseFormData {
    airline: AirlineField = new AirlineField(
        this,
        null,
        'airline',
        "Please select the airline for the flight"
    )
    flightNr: FormField<string> = new TextField(
        this,
        '',
        'flight_number',
        "Please enter the flight number"
    )
    departureAirport: AirportField = new AirportField(
        this,
        null,
        'departure_airport',
        'departureAirport',
        "Please select the departure airport"
    )
    destinationAirport: AirportField = new AirportField(
        this,
        null,
        'destination_airport',
        'destinationAirport',
        "Please select the destination airport"
    )
    departureDate: DateField = new DateField(this, null, null)
    departureTime: TimeField = new TimeField(this, null, null)
    flightIssue: TextField = new TextField(
        this,
        null,
        'flight_issue',
        "Please select the issue with your flight"
    )
    statedCause: TextField = new TextField(
        this,
        null,
        'stated_cause',
        "Please select the explanation given by the airline"
    )

    getFields () {
        return [
            this.airline,
            this.flightNr,
            this.departureAirport,
            this.destinationAirport,
            this.departureDate,
            // this.departureTime,
            this.flightIssue,
            this.statedCause
        ]
    }
}


export class FullClaimValues extends BaseFormData {
    flightValues: FlightValues
    claimValues: ClaimValues
    requireIdDoc: boolean

    constructor (onChange, requireIdDoc = false) {
        super()

        this.requireIdDoc = requireIdDoc
        this.flightValues = new FlightValues(onChange)
        this.claimValues = new ClaimValues(onChange, requireIdDoc)
    }

    getFields () {
        return (this.flightValues.getFields() as Array<FormField<any>>).concat(
            this.claimValues.getFields()
        )
    }

    formatDepartureTime () {
        // departureTime and departureDate assumed to be valid at this point

        // User entered date is in the device tz, we want it in the departure airport tz.
        // Send the date values as entered by the user and let the server interpret it.

        // let [, hours, minutes] = TIME_REGEX.exec(this.flightValues.departureTime.value)
        let [hours, minutes] = ['12', '00']
        hours = String(hours)
        if (hours.length == 1) {
            hours = '0' + hours
        }

        minutes = String(minutes)
        if (minutes.length == 1) {
            minutes = '0' + minutes
        }

        let departureDate = this.flightValues.departureDate.value
        let year = String(departureDate.getFullYear())

        let month = String(departureDate.getMonth() + 1)
        if (month.length == 1) {
            month = '0' + month
        }

        let day = String(departureDate.getDate())
        if (day.length == 1) {
            day = '0' + day
        }

        return `${year}-${month}-${day}T${hours}:${minutes}:00Z`
    }

    serialize () {
        let data = super.serialize()

        let submitter = this.claimValues.submitter.value

        let uploads = [submitter.boardingPassDoc.value.id]
        if (this.requireIdDoc) {
            uploads.push(submitter.idDoc.value.id)
        }

        for (let p of this.claimValues.extraPassengers.value) {
            uploads.push(p.boardingPassDoc.value.id)
            if (this.requireIdDoc) {
                uploads.push(p.idDoc.value.id)
            }
        }

        data['name'] = submitter.name.value
        data['uploads'] = uploads
        data['departure_time'] = this.formatDepartureTime()
        if (data['stated_cause'] == StatedCause.NO_EXPLANATION) {
            data['stated_cause'] = null
        }

        if (!data['comment'] || data['comment'].trim() == '') {
            data['comment'] = null
        }

        if (!data['phone_number'] || data['phone_number'].trim() == '') {
            data['phone_number'] = null
        }

        return data
    }
}
