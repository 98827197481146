//List of IBAN countries - https://openiban.com/countries
//List of EEA countries - http://worldpopulationreview.com/countries/eea-countries/

export const EEACountriesAndCHE = [
    {"name": "Austria","ibanCode": "AT"},
    {"name": "Belgium","ibanCode": "BE"},
    {"name": "Bulgaria","ibanCode": "BG"},
    {"name": "Cyprus","ibanCode": "CY"},
    {"name": "Czech Republic","ibanCode": "CZ"},
    {"name": "Denmark","ibanCode": "DK"},
    {"name": "Estonia","ibanCode": "EE"},
    {"name": "Finland","ibanCode": "FI"},
    {"name": "France","ibanCode": "FR"},
    {"name": "Germany","ibanCode": "DE"},
    {"name": "Greece","ibanCode": "GR"},
    {"name": "Hungary","ibanCode": "HU"},
    {"name": "Iceland","ibanCode": "IS"},
    {"name": "Ireland","ibanCode": "IE"},
    {"name": "Italy","ibanCode": "IT"},
    {"name": "Latvia","ibanCode": "LV"},
    {"name": "Liechtenstein","ibanCode": "LI"},
    {"name": "Lithuania","ibanCode": "LT"},
    {"name": "Luxembourg","ibanCode": "LU"},
    {"name": "Malta","ibanCode": "MT"},
    {"name": "Netherlands","ibanCode": "NL"},
    {"name": "Norway","ibanCode": "NO"},
    {"name": "Poland","ibanCode": "PL"},
    {"name": "Portugal","ibanCode": "PT"},
    {"name": "Romania","ibanCode": "RO"},
    {"name": "Slovakia","ibanCode": "SK"},
    {"name": "Slovenia","ibanCode": "SI"},
    {"name": "Spain","ibanCode": "ES"},
    {"name": "Sweden","ibanCode": "SE"},
    {"name": "United Kingdom","ibanCode": "GB"},
    {"name": "Switzerland","ibanCode": "CH"}
]
