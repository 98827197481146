import {
    FormField, TextField, PassengerNameField, BaseFormData, OptionalTextField, EmailField
} from '../ClaimFormV1/FormData'
import * as IBAN_TOOLS from 'ibantools'

export enum PaymentMethod {
    BANK_TRANSFER = 'bank_transfer',
	PAYPAL = 'paypal'
}


class SWIFTCodeField extends TextField {
    validate () {
        super.validate()

        if (!this.error && !IBAN_TOOLS.isValidBIC(this.value)) {
            this.setError("Please enter your SWIFT code correctly")
        }

        return this.isValid()
    }
}


export class PaymentDetailsValues extends BaseFormData {
    is_iban = new FormField<boolean>(this, true, 'is_iban')
    account = new TextField(this, '', 'account_number', 'Please enter your account number')
    email = new EmailField(this, '', 'email')
    bank_code = new SWIFTCodeField(this, '', 'bank_code', 'Please enter your SWIFT code')
    name = new PassengerNameField(this, '', 'holder_name')
    bank_name = new TextField(this, '', 'bank_name', 'Please enter the name of your bank')
    payment_method = new FormField<PaymentMethod>(this, PaymentMethod.BANK_TRANSFER, 'payment_method')
    country = new TextField(this, '', 'holder_country', 'Please enter the account holders country')
    city = new TextField(this, '', 'holder_city', 'Please enter the account holders city')
    postcode = new TextField(this, '', 'holder_postcode', 'Please enter the account holders postcode')
    first_address = new TextField(this, '', 'holder_first_address', 'Please enter the account holders first address')
    second_address = new OptionalTextField(this, '', 'holder_second_address', 'Please enter the account holders second address')

    getFields () {
        let fields = [
            this.name,
            this.account,
            this.is_iban,
            this.payment_method,
        ]

        if (this.payment_method.value == PaymentMethod.BANK_TRANSFER) {
            fields = fields.concat([this.bank_name])
            
            if (!this.is_iban.value) {
                fields = fields.concat([
                    this.bank_code, 
                    this.country,
                    this.city,
                    this.postcode,
                    this.first_address,
                    this.second_address
                ])
            }
        }

        if (this.payment_method.value == PaymentMethod.PAYPAL) {
            fields = fields.concat([this.email])
        }

        return fields
    }
}