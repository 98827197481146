import * as React from 'react'
import * as Moment from 'moment'
import {Container, Row, Col, Alert} from 'reactstrap'
import BankDetailsForm from '../components/BankDetailsForm/BankDetailsForm'
import { Spinner } from 'react-activity'
import {Helmet} from 'react-helmet'
import Layout from '../components/Layout/Layout'


interface Props {
    location: Location
}


export default class BankDetailsPage extends React.Component<Props> {
    requestId = null

    state = {
        dataLoaded: false,
        formClosed: false,
        notFound: false,
        serverError: false,

        airlineName: null,
        fromCity: null,
        toCity: null,
        flightDate: null,
    }

    async fetchRequestData () {
        let response = await fetch(`/api/payment-details/${this.requestId}`)

        if (response.ok) {

            if (response.status == 201) {
                this.setState({
                    dataLoaded: true,
                    formClosed: true,
                })
            } else {
                let data = await response.json()

                this.setState({
                    dataLoaded: true,
                    airlineName: data.airline_name,
                    fromCity: data.from_city,
                    toCity: data.to_city,
                    flightDate: Moment.utc(data.flight_date)
                })
            }
        } else {
            if (response.status == 404) {
                this.setState({
                    dataLoaded: true,
                    notFound: true,
                })
            } else {
                this.setState({
                    dataLoaded: true,
                    serverError: true,
                })
            }
        }
    }

    componentDidMount () {
        this.requestId = this.props.location.pathname.split('/').pop()
        this.fetchRequestData()
    }

    render () {
        if (!this.state.dataLoaded) {
            return <PageLoading/>
        }

        if (this.state.notFound) {
            return <RequestNotFound/>
        }

        if (this.state.formClosed) {
            return <FormClosed/>
        }

        return <Layout>
        <Container>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>

            <Row>
                <Col md={{size:8, offset: 2}} className="mb-5">
                    <h2 className="mt-5 mb-5">
                        Bank account details for compensation payment
                    </h2>

                    <p>
                        Good news!
                    </p>

                    <p>
                        <b>{this.state.airlineName}</b> has confirmed it will pay {" "}
                        compensation for your flight from <b>{this.state.fromCity}</b> to {" "}
                        <b>{this.state.toCity}</b> on <b>{this.state.flightDate.format('DD.MM.YYYY')}</b>.
                    </p>

                    <p>
                        It will usually take a while (up to 30 days) for the airline to fulfill
                        its obligation, but as soon as it's done, we will transfer the compensation
                        to your bank account.
                    </p>

                    <p className="mb-5">
                        Please provide the bank account details to which your compensation
                        should be paid using the secure form below.
                    </p>

                    <BankDetailsForm requestId={this.requestId}/>
                </Col>
            </Row>
        </Container>
        </Layout>
    }
}


class PageLoading extends React.Component {
    render () {
        return <Container>
            <Row>
                <Col><Spinner color="royalblue"/>Loading</Col>
            </Row>
        </Container>
    }
}


class RequestNotFound extends React.Component {
    render () {
        return <Container>
            <Alert color="danger" className="pt-3 pb-3 mt-5 mb-5">
                We could not find the requested claim, please ensure that the you
                have entered the correct and complete link.
            </Alert>
        </Container>
    }
}

class FormClosed extends React.Component {
    render () {
        return <Container>
            <Alert color="warning" className="pt-3 pb-3 mt-5 mb-5">
                Bank details for your claim have already been confirmed, this page is no longer active.
            </Alert>
        </Container>
    }
}