import * as React from 'react'
import {Form, FormGroup, Label, Input, Button, Alert} from 'reactstrap'
import { Spinner } from 'react-activity'
import {FormField} from '../ClaimFormV1/FormData'
import {PaymentDetailsValues, PaymentMethod} from './BankDetailsFormData'
import * as IBAN_TOOLS from 'ibantools'
import CountryAutocomplete from './CountryAutocomplete'
import {EEACountriesAndCHE} from './EEACountriesAndCHE'
import { PhonePrefix } from '../ClaimForm/CountriesAndPhonePrefixes'


interface Props {
    requestId: string
}

function renderError (field:FormField<any>) {
    if (field.error) {
        return <Alert color="danger">{field.error}</Alert>
    }
}

export default class BankDetailsForm extends React.Component<Props> {
    state = {
        submitting: false,
        submitted: false,
        serverError: false,

        errorMessage: null,
        formValues: null as PaymentDetailsValues,
    }

    constructor (props) {
        super(props)

        this.state.formValues = new PaymentDetailsValues(this.onFormChange)
    }

    onFormChange = () => {
        // TODO: rather hamfisted way to propagate changes, watch out for perf problems
        let newFormValues = new PaymentDetailsValues(this.onFormChange)
        Object.assign(newFormValues, this.state.formValues)
        this.setState({formValues: newFormValues})
    }

    onSubmit = async (e) => {
        e.preventDefault()

        if (this.state.formValues.payment_method.value == PaymentMethod.PAYPAL) {
            this.state.formValues.is_iban.setValue(false)
            this.state.formValues.account.setValue(this.state.formValues.email.value)
        }

        if (!this.state.formValues.validate()) {
            return
        }

        const apiValues = this.state.formValues.serialize()

        if (this.state.formValues.payment_method.value == PaymentMethod.BANK_TRANSFER &&
            (!apiValues['is_iban'] || !this.isEEACountryOrCHE())) {
            apiValues['holder_address'] = 
                apiValues['holder_country'] + ',' +
                apiValues['holder_city'] + ',' +
                apiValues['holder_postcode'] + ',' + 
                apiValues['holder_first_address'] + ',' +
                apiValues['holder_second_address']
        }

        this.setState({submitting: true})
        
        let response = await fetch(
            `/api/payment-details/${this.props.requestId}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(apiValues),
            }
        )

        if (response.ok) {
            this.setState({
                submitted: true,
                submitting: false,
                serverError: false,
            })
        } else {
            let errorMessage
            try {
                errorMessage = (await response.json()).message
            } catch (e) {
                errorMessage = response.statusText
            }
            this.setState({
                submitting: false,
                serverError: true,
                errorMessage,
            })
        }
    }

    showNonIBANFields () {
        return !this.state.formValues.is_iban
    }

    isEEACountryOrCHE () {
        let iban = this.state.formValues.account.value
        
        let result = EEACountriesAndCHE.filter(item => {
            return item.ibanCode.indexOf(iban.substring(0,2)) != -1
        })

        return result.length ? true : false
    }

    renderSwitch (values) {
        switch(values.payment_method.value) {
            case PaymentMethod.BANK_TRANSFER:
                return <BankTransferForm values={values} isEEACountryOrCHE={this.isEEACountryOrCHE()} />
            case PaymentMethod.PAYPAL:
                return <PaypalForm values={values} />
        }
    }

    render () {
        if (this.state.submitted) {
            return <SubmittedSuccessfully/>
        }

        let values = this.state.formValues

        return <Form onSubmit={this.onSubmit}>
            <p>
                Payment method:
            </p>

            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="payment_method"
                        value={PaymentMethod.BANK_TRANSFER}
                        checked={values.payment_method.value == PaymentMethod.BANK_TRANSFER}
                        onChange={(e) => values.payment_method.setValue(e.target.value as PaymentMethod)}
                    />
                    {' '} Bank transfer
                </Label>
            </FormGroup>

            <FormGroup check>
                <Label check>
                    <Input
                        type="radio"
                        name="payment_method"
                        value={PaymentMethod.PAYPAL}
                        checked={values.payment_method.value == PaymentMethod.PAYPAL}
                        onChange={(e) => values.payment_method.setValue(e.target.value as PaymentMethod)}
                    />
                    {' '} PayPal
                </Label>
            </FormGroup>

            {this.renderSwitch(values)}

            { this.state.serverError ?
                <Alert color="danger">
                    There was a server problem while saving your account details,
                    please try again later
                </Alert>
                :
                null
            }

            <Button block color="primary" className="mb-5" disabled={this.state.submitting}>
                { this.state.submitting ? <Spinner color="white"/> : null } Confirm account details
            </Button>
        </Form>
    }
}


class SubmittedSuccessfully extends React.Component {
    render () {
        return <Alert color="success">
            Thank you! Your bank account details have been saved. We will inform you when
            we have transferred your compensation.
        </Alert>
    }
}

interface BankTransferFormProps {
    values: PaymentDetailsValues,
    isEEACountryOrCHE: boolean,
}

class BankTransferForm extends React.Component<BankTransferFormProps> {
    onCountryChange = (selection: PhonePrefix[]) => {
        let values = this.props.values
        values.country.setValue(selection[0].name)

        this.setState({
            formValues: values
        })
    }

    render () {
        let values = this.props.values

        return <div>
            <FormGroup className="mt-5 mb-4">
                <Label>Full name of account holder</Label>
                <Input
                    type="text"
                    value={values.name.value}
                    onChange={(e) => values.name.setValue(e.target.value)}
                    onFocus={() => values.name.setError(null)}
                    onBlur={() => values.name.validate()}
                />
                { renderError(values.name) }
            </FormGroup>

            <FormGroup>
                <Label>Name of bank</Label>
                <Input
                    type="text"
                    value={values.bank_name.value}
                    onChange={(e) => values.bank_name.setValue(e.target.value)}
                    onFocus={() => values.bank_name.setError(null)}
                    onBlur={() => values.bank_name.validate()}
                />
                { renderError(values.bank_name) }
            </FormGroup>

            <FormGroup className="mb-4">
                <Label>Account number</Label>
                <Input
                    type="text"
                    value={values.account.value}
                    onChange={(e) => {
                        values.account.setValue(e.target.value)

                        if (!e.target.value || e.target.value.trim().length < 7) {
                            values.is_iban.setValue(true)
                        } else {
                            values.is_iban.setValue(
                                IBAN_TOOLS.isValidIBAN(e.target.value.replace(/\s/g, ''))
                            )
                        }
                    }}
                    onFocus={() => values.account.setError(null)}
                    onBlur={() => values.account.validate()}
                />
                { renderError(values.account) }

            </FormGroup>

            { !values.is_iban.value || !this.props.isEEACountryOrCHE ?
                <>
                    <FormGroup className="mt-5">
                        <Alert color="primary" className="">
                            The number you entered doesn't appear to be an IBAN account number
                            commonly used in the EU.
                            <ul className="mt-3">
                                <li className="mb-2">
                                    If your bank is based in the EU, please check that you have
                                    entered the account number correctly
                                </li>
                                <li>
                                    If your bank is based in UK or outside the EU, then we'll need
                                    some more information to successfully transfer your compensation:
                                </li>
                            </ul>
                        </Alert>
                        <Label>
                            Bank code
                        </Label>
                        <Input
                            type="text"
                            value={values.bank_code.value}
                            onChange={(e) => values.bank_code.setValue(e.target.value)}
                            onFocus={() => values.bank_code.setError(null)}
                            onBlur={() => values.bank_code.validate()}
                        />
                        <small>
                            May also be called SWIFT, BIC or sort code, depending on your country and bank
                        </small>
                        { renderError(values.bank_code) }

                    </FormGroup>

                    <FormGroup>
                        <Label>Country</Label>
                        <CountryAutocomplete 
                            id="country"
                            name="country"
                            onChange={this.onCountryChange}
                            onFocus={() => values.country.setError(null)}
                            onBlur={() => values.country.validate()}
                        />
                        { renderError(values.country) }
                    </FormGroup>

                    <FormGroup>
                        <Label>City</Label>
                        <Input
                            type="text"
                            value={values.city.value}
                            onChange={(e) => values.city.setValue(e.target.value)}
                            onFocus={() => values.city.setError(null)}
                            onBlur={() => values.city.validate()}
                        />
                        { renderError(values.city) }
                    </FormGroup>

                    <FormGroup>
                        <Label>Postcode</Label>
                        <Input
                            type="text"
                            value={values.postcode.value}
                            onChange={(e) => values.postcode.setValue(e.target.value)}
                            onFocus={() => values.postcode.setError(null)}
                            onBlur={() => values.postcode.validate()}
                        />
                        { renderError(values.postcode) }
                    </FormGroup>

                    <FormGroup>
                        <Label>Address line #1</Label>
                        <Input
                            type="text"
                            value={values.first_address.value}
                            onChange={(e) => values.first_address.setValue(e.target.value)}
                            onFocus={() => values.first_address.setError(null)}
                            onBlur={() => values.first_address.validate()}
                        />
                        { renderError(values.first_address) }
                    </FormGroup>

                    <FormGroup className="mb-4 mb-5">
                        <Label>Address line #2 (optional)</Label>
                        <Input
                            type="text"
                            value={values.second_address.value}
                            onChange={(e) => values.second_address.setValue(e.target.value)}
                            onFocus={() => values.second_address.setError(null)}
                            onBlur={() => values.second_address.validate()}
                        />
                        { renderError(values.second_address) }
                    </FormGroup>
                </>
                :
                null
            }
        </div>
    }
}

class PaypalForm extends React.Component<{values: PaymentDetailsValues}> {
    render () {
        let values = this.props.values

        return <div>
            <FormGroup className="mt-5 mb-4">
                <Label>Full name</Label>
                <Input
                    type="text"
                    value={values.name.value}
                    onChange={(e) => values.name.setValue(e.target.value)}
                    onFocus={() => values.name.setError(null)}
                    onBlur={() => values.name.validate()}
                />
                { renderError(values.name) }
            </FormGroup>

            <FormGroup>
                <Label>E-mail address</Label>
                <Input
                    type="email"
                    value={values.email.value}
                    onChange={(e) => values.email.setValue(e.target.value)}
                    onFocus={() => values.email.setError(null)}
                    onBlur={() => values.email.validate()}
                />
                { renderError(values.email) }
            </FormGroup>
        </div>
    }
}